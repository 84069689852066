import React, {useEffect, useRef} from 'react';
import styles from './loader.module.css'

export function Loader(props) {

    const loaderRef = useRef()

    useEffect(() => {
        if(props.data && document.getElementById("loader")) {
            loaderRef.current.style.transition = "0.4s all"
            if(props.scroll !== false) {
                window.scrollTo(0,0)
            }
            setTimeout(() => {
                loaderRef.current.style.opacity = "0"
                loaderRef.current.style.visibility = "hidden"
            },100)
        } else {
            loaderRef.current.style.transition = "0s all"
            loaderRef.current.style.opacity = "1"
            loaderRef.current.style.visibility = "visible"
        }
    },[props.data, props.scroll])

    useEffect(() => {
        if(props.builder) {
            loaderRef.current.style.visibility = "visable"
        }
    },[props.builder])

    return (
        <div ref={loaderRef} className={`${styles.loader} ${props.data ? styles.loaderHide : ''}`} id={"loader"}>
            <div className={styles.wrapperMain}>
                <div className={styles.wrapper}>
                    <div className={styles.bubbleWrap}>
                        <div className={styles.bubble}></div>
                    </div>
                    <div className={styles.bubbleWrap}>
                        <div className={styles.bubble}></div>
                    </div>
                    <div className={styles.bubbleWrap}>
                        <div className={styles.bubble}></div>
                    </div>
                    <div className={styles.bubbleWrap}>
                        <div className={styles.bubble}></div>
                    </div>
                    <div className={styles.bubbleWrap}>
                        <div className={styles.bubble}></div>
                    </div>
                    <div className={styles.bubbleWrap}>
                        <div className={styles.bubble}></div>
                    </div>
                    <div className={styles.bubbleWrap}>
                        <div className={styles.bubble}></div>
                    </div>
                    <div className={styles.bubbleWrap}>
                        <div className={styles.bubble}></div>
                    </div>
                    <div className={`${styles.satWrap} ${props.builder ? styles.satWrapBuilder : ''}`}>
                        <div className={styles.sat}></div>
                    </div>
                </div>
                {!props.builder ?
                    <svg style={{position: "absolute"}}>
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur"></feGaussianBlur>
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                                               result="goo"></feColorMatrix>
                                <feBlend in="SourceGraphic" in2="goo"></feBlend>
                            </filter>
                        </defs>
                    </svg>
                : null}
            </div>
        </div>
    );
}