import React, { lazy, Suspense} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import '../../assets/styles/index.scss';
import {Loader} from "../../Components/Loader/loader";


/*import Header from "../Header/header";
import Footer from "../Footer/footer";
import MainPage from "../MainPage/mainPage";
import BlogMain from "../BlogMain/blogMain";
import BlogArticle from "../BlogArticle/blogArticle";
import About from "../About/about";
import HeatPump from "../HeatPump/heatPump";
import Photovoltaics from "../Photovoltaics/photovoltaics";
import Chargers from "../Chargers/chargers";
import ElectricCars from "../ElectricCars/electricCars";
import GreenBusiness from "../GreenBusiness/greenBusiness";
import RealizationsAndOpinions from "../RealizationsAndOpinions/realizationsAndOpinions";*/

const Header = lazy(() => import('../Header/header'));
const MainPage = lazy(() => import('../MainPage/mainPage'));
const BlogMain = lazy(() => import('../BlogMain/blogMain'));
const BlogArticle = lazy(() => import('../BlogArticle/blogArticle'));
const About = lazy(() => import('../About/about'));
const HeatPump = lazy(() => import('../HeatPump/heatPump'));
const Photovoltaics = lazy(() => import('../Photovoltaics/photovoltaics'));
const Chargers = lazy(() => import('../Chargers/chargers'));
const ElectricCars = lazy(() => import('../ElectricCars/electricCars'));
const GreenBusiness = lazy(() => import('../GreenBusiness/greenBusiness'));
const RealizationsAndOpinions = lazy(() => import('../RealizationsAndOpinions/realizationsAndOpinions'));
const BlogCategory = lazy(() => import('../BlogCategory/blogCategory'));
const Career = lazy(() => import('../Career/career'));

const App = ({match, location}) =>{
    return (
        <Suspense fallback={<Loader builder={true} />}>
            <Switch>
                <Route exact path='/:lang(pl)'>
                    <Header />
                    <MainPage/>
                </Route>

                <Route exact path='/:lang(pl)/blog'>
                    <Header />
                    <BlogMain/>
                </Route>

                <Route exact path='/:lang(pl)/blog/:id'>
                    <Header />
                    <BlogArticle/>
                </Route>

                <Route exact path='/:lang(pl)/blog/kategoria/:category'>
                    <Header />
                    <BlogCategory/>
                </Route>

                <Route exact path='/:lang(pl)/o-nas'>
                    <Header />
                    <About/>
                </Route>

                <Route exact path='/:lang(pl)/zielony-biznes'>
                    <Header />
                    <GreenBusiness/>
                </Route>

                <Route exact path='/:lang(pl)/realizacje-i-opinie'>
                    <Header />
                    <RealizationsAndOpinions/>
                </Route>

                <Route exact path='/:lang(pl)/pompy-ciepla'>
                    <Header />
                    <HeatPump/>
                </Route>

                <Route exact path='/:lang(pl)/fotowoltaika'>
                    <Header />
                    <Photovoltaics/>
                </Route>

                <Route exact path='/:lang(pl)/ladowarki-do-aut-elektrycznych'>
                    <Header />
                    <Chargers/>
                </Route>

                <Route exact path='/:lang(pl)/samochody-elektryczne'>
                    <Header />
                    <ElectricCars/>
                </Route>

                <Route exact path='/:lang(pl)/kariera'>
                    <Career/>
                </Route>

                <Route exact path={`*`}>
                    <Redirect to="/pl" />
                </Route>
            </Switch>
        </Suspense>
    );
};


function Builder() {
    return (
        <Router>
            <App/>
        </Router>
    );
}

export default Builder;